<template>
  <div class="select-pokemon-page">
    <img src="./../assets/logo-pixel.gif" alt="pokémon" />
    <h1>Home page</h1>
    <div class="select-pokemon-content">
      <pokemon-list
        :pokemon-list="statePokemonDataList"
        :favorite-list="stateFavoritePokemonList"
        @addFavorite="addFavorite"
        @deleteFavorite="deleteFavorite"
      ></pokemon-list>
      <favorite-summary
        :favorite-list="stateFavoritePokemonList"
        @eraseFavoritePokemonList="eraseFavoritePokemonList"
      ></favorite-summary>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PokemonList from "../components/PokemonList";
import FavoriteSummary from "../components/FavoriteSummary";

export default {
  components: {
    PokemonList,
    FavoriteSummary,
  },
  computed: {
    ...mapState(["statePokemonDataList", "stateFavoritePokemonList"]),
  },
  async created() {
    const pokemonData = await this.getPokemonData();
    this.setPokemonData(pokemonData);
  },
  methods: {
    async getPokemonData() {
      const data = await fetch("https://pokeapi.co/api/v2/pokemon?limit=151");
      const json = await data.json();
      return json.results;
    },
    ...mapActions([
      "setPokemonData",
      "addFavorite",
      "deleteFavorite",
      "eraseFavoritePokemonList",
    ]),
  },
};
</script>

<style scoped>
.select-pokemon-page {
  text-align: center;
}
.select-pokemon-content {
  text-align: left;
  display: flex;
  justify-content: space-evenly;
}
</style>