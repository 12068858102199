<template>
  <div>
    <h2>Choose your Pokemon.</h2>
    <p v-for="(pokemon, index) in pokemonList" :key="pokemon.url">
      {{ index + 1 + ". " }}
      {{ pokemon.name }}
      <img
        :key="pokemon.url"
        :src="`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${
          index + 1
        }.png`"
      />
      <button
        v-show="!favoriteList.includes(pokemon.name)"
        :class="{ 'is-disabled': favoriteListLength === 10 }"
        @click="setFavorite(pokemon.name)"
        class="nes-btn"
      >
        Add
      </button>
      <button
        v-show="favoriteList.includes(pokemon.name)"
        class="nes-btn is-error"
        @click="setFavorite(pokemon.name)"
      >Remove</button>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    pokemonList: {
      type: Array,
      required: true,
    },
    favoriteList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    favoriteListLength() {
      return this.favoriteList.length;
    },
  },
  methods: {
    setFavorite(name) {
      if (this.favoriteList.includes(name)) {
        const INDEX = this.favoriteList.indexOf(name);
        this.$emit("deleteFavorite", INDEX);
        return
      }
      if (this.favoriteList.length < 10) {
        this.$emit("addFavorite", name);
      }
    },
  },
};
</script>