<template>
  <div>
    <h1 class="overview-favorite-pokemon__title">Your favorite Pokémon</h1>
    <router-link class="nes-btn" to="/"> Go back </router-link>
    <div class="overview-favorite-pokemon">
      <favorite-card
        v-for="(pokemon, index) in stateFavoritePokemonList"
        :key="index"
        :pokemon-name="pokemon"
      ></favorite-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FavoriteCard from "../components/FavoriteCard";

export default {
  components: {
    FavoriteCard,
  },
  computed: {
    ...mapState(["stateFavoritePokemonList"]),
  },
};
</script>

<style scoped>
.overview-favorite-pokemon {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.overview-favorite-pokemon__title {
  margin-top: 10px;
  text-align: center;
}
</style>