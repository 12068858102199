<template>
  <div>
    <h3>Pick a favorite pokemon!</h3>
    <div>
      <p
        v-for="(pokemon, index) in favoriteList"
        :key="index"
        class="chosen-pokemon"
      >
        <img src="../assets/pokeball.png" /> {{ pokemon }}
      </p>
    </div>
    <p v-if="favoriteListLength >= 1 && favoriteListLength < 10">
      You can choose {{ 10 - favoriteListLength }} more.
    </p>
    <p v-if="favoriteListLength >= 10">You can't choose more!</p>
    <button
      class="nes-btn is-error"
      @click="eraseFavoritePokemonList"
      v-if="favoriteListLength >= 1 && favoriteListLength < 10"
    >
      Delete the list
    </button>
    <router-link to="/favorites" class="nes-btn"
      >View favorite pokemons.</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    favoriteList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    favoriteListLength() {
      return this.favoriteList.length;
    },
  },
  methods: {
    eraseFavoritePokemonList() {
      this.$emit("eraseFavoritePokemonList");
    },
  },
};
</script>

<style scoped>
.chosen-pokemon {
  text-transform: capitalize;
}
</style>